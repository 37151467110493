import React, { Component } from 'react';
import Form from "react-bootstrap/Form";
import {Button, FormControl, InputGroup,Dropdown, DropdownButton} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

class SearchTab extends Component {
 
    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        if (this.state.pathly[4] !== undefined) {

            console.log(this.state.pathly[4])
            window.location.pathname =  "Deals/search=" + this.state.value + "/" + this.state.pathly[3] + "/"+ this.state.pathly[4] +"/"+ this.state.pathly[5]
        } else {
            console.log("itworked")
            console.log(this.state.pathly[4])

            window.location.pathname =  "Deals/search=" + this.state.value + "/company=none/category=none/price=none"
        }

     event.preventDefault();
    }

    dropDownTitle() {
            if (this.state.pathly[1] === "Deals") {
                if (this.state.pathly[4].split("=")[1] === "none") {
                    return "All"
                } else {
                    return this.state.pathly[4].split("=")[1]//.replace("%20", " ").replace("%20", " ")
                }

            } else {
                return "All"
            }
    }

    getSearchValue () {
        if (this.state.pathly[1] === "Deals") {

            if (this.state.pathly[2].split("=")[1] === "none") {
                return "Search"
            } else {
                return this.state.pathly[2].split("=")[1]//.replace("%20", " ").replace("%20", " ")
            }

        } else {
            return "Search"
        }
    }

    render() {
        return (

            <Form style={{width: "100%",paddingLeft: "5%",paddingRight:"5%"}} onSubmit={this.handleSubmit}>
                <InputGroup className="mb-3">
                    {/*<DropdownButton*/}
                    {/*    as={InputGroup.Append}*/}
                    {/*    variant="outline-light"*/}
                    {/*    title={this.dropDownTitle()}*/}
                    {/*   // onChange={console.log("jj")}*/}
                    {/*    id="input-group-dropdown-1">*/}

                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Arts & Crafts" >Arts & Crafts</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Baby">Baby</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Beauty & Grooming">Beauty & Grooming</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Books">Books</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Electronics">Electronics</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Shoes">Shoes</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Handbags & Wallets">Handbags & Wallets</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Accessories & Jewelry">Accessories & Jewelry</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Toys & Video Games">Toys & Video Games</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Office & School">Office & School</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Home">Home</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Household & Grocery">Household & Grocery</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Health">Health</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Clothing">Clothing</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Mens">Mens</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Sports & Fitness">Sports & Fitness</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Pets">Pets</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Outdoor">Outdoor</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Kitchen & Dining">Kitchen & Dining</Dropdown.Item>*/}
                    {/*    <Dropdown.Item href="/OldDeals/search=none/company=none/filter=Automotive">Automotive</Dropdown.Item>*/}
                    {/*</DropdownButton>*/}

                    <FormControl
                        type="text"  onChange={this.handleChange}
                        placeholder={this.getSearchValue()}
                        aria-label="Search"

                        aria-describedby="basic-addon2"
                    />

                    <InputGroup.Prepend>
                        <Button type="submit" value="Submit"
                                variant="outline-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-search" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg></Button>
                    </InputGroup.Prepend>

                </InputGroup>
        </Form>

        );
    }
}

export default SearchTab;