import React, {Component} from "react";
import Loading from "../Loading";
import Dropdown from "react-bootstrap/Dropdown";
import {VscHeart, VscSignIn, VscSignOut} from "react-icons/vsc";
import {googleSignInRedirectResult, signOutUser} from "../Firebase/google-signin";
import firebase from "firebase";
const provider = new firebase.auth.GoogleAuthProvider();

class SignInDropdownItems extends Component {
    constructor(props) {
        //SEARCH BAR DEFAULT VALUE LOGIC
        var searchQuery = ""
        super(props);
        this.state = {value:searchQuery,
            isLoaded: false,
            userSignedIn: firebase.auth().currentUser,
        }


    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
    componentDidMount() {
        this.setState({isLoaded: true})
    }

    render() {
        if(!this.state.isLoaded) {
return <Loading/>
        } else {
            return (<div>

                    <Dropdown.Item hidden={this.state.userSignedIn === null} href={"/saved"} ><VscHeart/> Saved </Dropdown.Item>
                    <Dropdown.Item hidden={this.state.userSignedIn === null} onClick={signOutUser} ><VscSignOut/> Sign Out </Dropdown.Item>
                    <Dropdown.Item hidden={this.state.userSignedIn !== null} onClick={ () => googleSignInRedirectResult(provider)}><VscSignIn/> Sign In </Dropdown.Item>

                </div>
            )
        }



    }
}
export default SignInDropdownItems;