import React, {Component, lazy} from 'react';
import {Spinner} from "react-bootstrap";
import TopNavBar from "./navBar/TopNavBar";


class Loading extends Component {
    render() {
        return (<div>

                <div style={{paddingTop: "100px",backgroundColor: "transparent"}}>
                    <div className={"thrifty-text"} style={{padding: "50px",textAlign:"center"}}>
                        <Spinner style={{width: "60px", height: "60px"}}
                                 animation="border"
                                 role="status">
                        </Spinner>
                        <h6 style={{padding: "20px"}}>Loading...</h6>
                    </div></div>
        </div>

        );
    }
}

export default Loading;