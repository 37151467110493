import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from "react-bootstrap/Dropdown";
import logo from '../StaticAssets/thriftyLogo.png'
import SearchTab from "./SearchTab";
import {VscWindow, VscQuestion, VscNote, VscHeart, VscSignOut, VscSignIn, VscSearch} from "react-icons/vsc";
import Button from "react-bootstrap/Button";
import {googleSigninLogic, googleSignInRedirectResult, signOutUser} from "../Firebase/google-signin";


import {FiFacebook, FiSend} from "react-icons/fi";
import SignInDropdownItems from "./SignInDropdownItems";
import firebase from "firebase";


const provider = new firebase.auth.GoogleAuthProvider();
let isUsersSignedIn = "";
class TopNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {value: '',
        searchBarIsHidden: false,
        userSignedIn: null, };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }


    setUserSignedIn(status) {
        if (status) {
           // this.setState({userSignedIn: true});
        } else {
          //  this.setState({userSignedIn: false});
        }
    }





    submitDealLink() {
        window.open("http://go.thriftybabes.com/DealSubmit", '_blank')

    }
    visitFacebook() {
        window.open("https://www.facebook.com/groups/ThriftyBabes", '_blank')

    }

    render() {
        // firebase.auth().onAuthStateChanged(function(user) {
        //     if (user) {
        //         // User is signed in
        //         try {
        //             this.setState({userSignedIn: true})
        //         } catch(error) {
        //
        //
        //         }
        //
        //
        //         console.log("user is signed in")
        //     } else {
        //         // No user is signed in
        //         this.setState({userSignedIn: false})
        //         console.log("not signed in", firebase.auth().currentUser)
        //     }
        //
        // });

        return (<div>
            <Navbar className='primary-bgc justify-content-between'  expand="lg" variant="dark">
            {/*Left Icon*/}
                <Navbar.Brand >
                    <Dropdown hidden={false}>
                        <Dropdown.Toggle
                            aria-controls="basic-navbar-nav"
                            style={{color: "#752757", stroke:"white",
                                backgroundColor: "#752757",
                                borderColor: "#752757"
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white"
                                 className="bi bi-list" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                            <Dropdown.Item href={"/Deals/search=none/company=none/category=none/price=none"} ><VscSearch/> Find Deals </Dropdown.Item>
                            <hr/>
                            <SignInDropdownItems/>
                            <hr/>
                            <Dropdown.Item href="/FAQ"><VscQuestion/> FAQ</Dropdown.Item>
                            <Dropdown.Item onClick={this.submitDealLink}><FiSend/> Submit A Deal</Dropdown.Item>
                            <Dropdown.Item onClick={this.visitFacebook}><FiFacebook/> Facebook Group</Dropdown.Item>
                            <Dropdown.Item href="/Privacy"> <small>Privacy  </small></Dropdown.Item>
                            <Dropdown.Item href="/About"> <small>About  </small></Dropdown.Item>
                            <Dropdown.Item href="/Disclosure"><small>Disclosure</small> </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar.Brand>

                {/*Middle Icon (was 30by30 before)*/}

            <Navbar.Brand  href="/"><img height="90px" width="90px" src={logo} alt="logo" /></Navbar.Brand>


            {/*Right Icon    */}
            <Navbar.Brand href="/faq" >
               <small><VscQuestion/> FAQ</small>
            </Navbar.Brand>
       </Navbar>

                <Navbar className='primary-bgc justify-content-between'>
                   {/*Search bar*/}



            </Navbar></div>

    )


    }
}

export default TopNavBar; // Don’t forget to use export default!