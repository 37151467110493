import React, {Suspense,lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import App from './App';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from "./Loading";
import reportWebVitals from './reportWebVitals';


// You can choose your kind of history here (e.g. browserHistory)
const App = lazy(() => import('./App'))
const Footer = lazy(() => import('./Footer/Footer'))
const TopNavBar = lazy(() => import("./navBar/TopNavBar"));
// lazy(() => import('bootstrap/dist/css/bootstrap.min.css'))

ReactDOM.render(

    <Suspense fallback={<Loading/>}>
        <TopNavBar/>
  <BrowserRouter>
      <App/>
  </BrowserRouter>
        <Footer/>
    </Suspense>,
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
